import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import AdministratorViewSidebar from "./Container/AdministratorViewSidebar/AdministratorViewSidebar";
import { Route, Routes, useNavigate } from "react-router-dom";
import PatientLogin from "./Components/Login/PatientLogin";
import EHRLogin from "./Components/Login/EHRLogin";
import PatientAccountRegistration from "./Components/PatientAccountRegistration/PatientAccountRegistration";
import PractitionerRegistrationForm from "./Components/PractitionerRegistration/PractitionerRegistrationForm";
import PatientViewSidebar from "./Container/PatientViewSidebar/PatientViewSidebar";
import VerifyAccountEmail from "./Components/PatientAccountRegistration/VerifyAccountEmail";
import OtpVerification from "./Components/Login/OtpVerification";
import MedSpaPrivacyPolicyPage from "./Components/MedSpaPrivacyPolicyPage/MedSpaPrivacyPolicyPage";
import ForgotPassword from "./Components/Login/ForgotPassword";
import ResetPassword from "./Components/Login/ResetPassword";
import BillQrPayment from "./Components/ServiceBill/BillQrPayment";
import ProductBillQrPayment from "./Components/ProductBilling/ProductBillQrPayment";
import NewRegisterPatientLogin from "./Components/Login/NewRegisterPatientLogin";
import WebRoutes from "./WebSite/Routes/WebRoutes/WebRoutes";
import PatientWalletTopUpQR from "./Components/PatientWallet/PatientWalletTopUpQR";
import devtools from 'devtools-detect';
import { HelmetProvider } from "react-helmet-async";

function App() {

  


  return (
    <HelmetProvider>
    <div className="App">
      <Routes>
        <Route path="/*" element=<WebRoutes /> />
        <Route path="/patient-login" element=<PatientLogin /> />
        <Route
          path="/login-authentication/:email"
          element=<OtpVerification />
        />
        <Route path="/providerlogin" element=<EHRLogin /> />
        <Route
          path="/new-register-login/:endodedEmail/:endodedPassword"
          element=<NewRegisterPatientLogin />
        />
        <Route path="/admin/*" element=<AdministratorViewSidebar /> />
        <Route path="/patient-dashboard/*" element=<PatientViewSidebar /> />
        <Route
          path="/patientregistration"
          element=<PatientAccountRegistration />
        />
        <Route
          path="/privacy-policy-terms"
          element=<MedSpaPrivacyPolicyPage />
        />

        <Route
          path="/practitionerregistration"
          element=<PractitionerRegistrationForm />
        />
        <Route path="/portal" element=<VerifyAccountEmail /> />
        <Route path="/forgot-password" element=<ForgotPassword /> />
        <Route path="/reset-password/:username" element=<ResetPassword /> />
        <Route
          path="/bill-qr/:fname/:lname/:email/:eid/:pid/:finalAmount/:BillId/:paymentFor"
          element=<BillQrPayment />
        />
        {/* Product Only */}
        <Route
          path="/products-bill-qr/:fname/:lname/:email/:finalAmount/:productBillData"
          element=<ProductBillQrPayment />
        />
        <Route
          path="/wallet-top-up-qr/:fname/:lname/:email/:finalAmount/:walletid/:paymentFor"
          element=<PatientWalletTopUpQR />
        />
      </Routes>
    </div>
    </HelmetProvider>
  );
}

export default App;
