import React from 'react'
import "./MedSpaPatientRegistrationPage.css"
import PatientRegistrationForm from '../../Components/PatientRegistration/PatientRegistrationForm/PatientRegistrationForm'
import { Fade } from 'react-reveal'
import PatientDetailsForm from '../../Components/PatientRegistration/PatientDetailsForm'
import { Helmet } from 'react-helmet-async'

const MedSpaPatientRegistrationPage = () => {
  return (
    <div className='med-spa-pat-reg-bg-colo-of-page'>
    <Helmet>
  <title>Sign Up for Med Spa Services | Medi Spa Cosmos</title>
  <meta name="description" content="Create your account and book premium Med Spa treatments in Bloomington, Indiana. Join us for expert skincare and cosmetic services." />
  <meta name="keywords" content="Med Spa Sign Up, Patient Registration, Book Med Spa Appointment, Online Booking, Skincare Registration" />
  <meta property="og:title" content="Sign Up for Med Spa Services | Medi Spa Cosmos" />
  <meta property="og:description" content="Register now to access the best Med Spa treatments in Bloomington, Indiana." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://medispacosmos.com/signup" />
</Helmet>

        <div style={{paddingTop:"30px",paddingBottom:"30px"}}>
        <Fade bottom>
            {/* <PatientRegistrationForm/> */}
            <PatientDetailsForm/>
            </Fade>
        </div>
    </div>
  )
}

export default MedSpaPatientRegistrationPage