import React from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import AppointmentCalendar from "../PatientAppointments/AppointmentCalendar";

const WorkingHours = () => {
  return (
    <div>
      <Container>
        <Row >
          <Col sm={12}>
            <Card style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 8px" }}>
              <Card.Body>
                <h1 className="section-one-head-tag">Working Hours</h1>
                {/* <Row>
                        <Col sm={4}>Thursday</Col>
                        <Col sm={4}>10 AM - 6 PM</Col>
                    </Row>
                    <Row>
                        <Col sm={4}>Friday</Col>
                        <Col sm={4}>10 AM - 6 PM</Col>
                    </Row>
                    <Row>
                        <Col sm={4}>Saturday</Col>
                        <Col sm={4}>10 AM - 6 PM</Col>
                    </Row> */}

                <Table
                  striped
                  bordered
                  hover
                  responsive
                  className="open-days-table"
                  style={{ marginTop: "20px" }}
                >
                  <thead>
                    <tr>
                      <th>Day</th>
                      <th>Opening Time</th>
                      <th>Closing Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* <tr>
                      <td>Wednesday</td>
                      <td>10:00 AM</td>
                      <td>6:00 PM</td>
                    </tr> */}
                    <tr>
                      <td>Thursday</td>
                      <td>12:00 AM</td>
                      <td>05:00 PM</td>
                    </tr>
                    <tr>
                      <td>Friday</td>
                      <td>12:00 AM</td>
                      <td>05:00 PM</td>
                    </tr>
                    <tr>
                      <td>Saturday</td>
                      <td>12:00 AM</td>
                      <td>05:00 PM</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          {/* <Col sm={6}>
          <Card style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 8px" }}>
          <Card.Body>
            <AppointmentCalendar />
            </Card.Body>
          </Card>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default WorkingHours;
