import React from 'react'
import "./MedSpaArticlesPage.css"
import ArticlesCard from './ArticlesCard'
import { Helmet } from 'react-helmet-async'

const MedSpaArticlesPage = () => {
  return (
    <div>
    <Helmet>
  <title>Beauty & Wellness Articles | Medi Spa Cosmos</title>
  <meta name="description" content="Stay updated with expert beauty and wellness tips from Medi Spa Cosmos. Read about skincare, anti-aging treatments, and the latest med spa trends." />
  <meta name="keywords" content="Beauty Tips, Skincare, Anti-Aging, Med Spa Blog, Wellness Articles, Skin Rejuvenation, Botox Advice, Facial Care" />
  <meta property="og:title" content="Beauty & Wellness Articles | Medi Spa Cosmos" />
  <meta property="og:description" content="Explore the latest articles on beauty, skincare, and wellness from Medi Spa Cosmos in Bloomington, Indiana." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://medispacosmos.com/articles" />
  <meta property="og:image" content="https://medispacosmos.com/cosmosLogo.svg" />
</Helmet>

      <div style={{paddingTop:"50px", paddingBottom:"30px"}}>
        <ArticlesCard/>
      </div>
    </div>
  )
}

export default MedSpaArticlesPage