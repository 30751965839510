import React from "react";
import MedSpaSectionOne from "../../Components/MedSpaSectionOne/MedSpaSectionOne";
import MedSpaTreatments from "../../Components/MedSpaTreatments/MedSpaTreatments";
import MedSpaSectionTwo from "../../Components/MedSpaSectionTwo/MedSpaSectionTwo";
import MedSpaSectionThree from "../../Components/MedSpaSectionThree/MedSpaSectionThree";
import MedSpaVisitUs from "../../Components/MedSpaVisitUs/MedSpaVisitUs";
import MedSpaFeedback from "../../Components/MedSpaFeedback/MedSpaFeedback";
import ArticlesCarousal from "../../Components/Articles/ArticlesCarousal/ArticlesCarousal";
import ProductCarousal from "../../Components/ProductCarousal/ProductCarousal";
import { Fade } from "react-reveal";
import WorkingHours from "../../Components/WorkingHours/WorkingHours";
import AppointmentCalendar from "../../Components/PatientAppointments/AppointmentCalendar";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

const   MedSpaHomePage = () => {
  return (
    <div>
    <Helmet>
  <title>Cosmos MediSpa - Bloomington, Indiana</title>
  <meta 
    name="description" 
    content="Experience the best Med Spa treatments in Bloomington, Indiana. From Botox and fillers to facials, microneedling, hormone therapy, and IPL skin rejuvenation, we provide top-quality care." 
  />
  <meta 
    name="keywords" 
    content="Med Spa Bloomington, Botox Bloomington Indiana, Dermal Fillers, Facials, Microneedling, PRP Hair Treatment, IPL Skin Rejuvenation, PDO Threads, IV Infusion Therapy, Weight Loss Treatments, Spider Vein Correction" 
  />
  <meta property="og:title" content="Medi Spa Cosmos - Bloomington, Indiana" />
  <meta 
    property="og:description" 
    content="Discover premium Med Spa treatments in Bloomington, Indiana. Enhance your beauty with Botox, fillers, facials, microneedling, hormone therapy, skin tightening, and more." 
  />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://medispacosmos.com/" />
  <meta property="og:image" content="https://medispacosmos.com/cosmosLogo.svg" />
</Helmet>

      <div style={{ paddingTop: "22px" }}>
        {
          <Fade bottom>
            <MedSpaSectionOne />
          </Fade>
        }
      </div>
      <div style={{ paddingTop: "80px" }}>
        {
          <Fade bottom>
            <MedSpaTreatments />
          </Fade>
        }
      </div>
      {/* <div style={{ paddingTop: "80px" }}>
        {
          <Fade bottom>
                <WorkingHours />
             </Fade>
        }
      </div> */}
      <div style={{ paddingTop: "80px" }}>
        {
          <Fade bottom>
            <MedSpaSectionTwo />
          </Fade>
        }
      </div>
      <div style={{ paddingTop: "80px" }}>
        {
          <Fade bottom>
            <ProductCarousal />
          </Fade>
        }
      </div>
      {/* <div style={{ paddingTop: "80px" }}>
        {
          <Fade bottom>
            <ArticlesCarousal />
          </Fade>
        }
      </div> */}
      {/* <div style={{ paddingTop: "80px" }}>
        {
          <Fade bottom>
            <MedSpaFeedback />
          </Fade>
        }
      </div> */}
      <div style={{ paddingTop: "80px" }}>
        {
          <Fade bottom>
            <MedSpaSectionThree />
          </Fade>
        }
      </div>
      {/* <div style={{ paddingTop: "80px" }}>
        {
          <Fade bottom>
            <WorkingHours />
          </Fade>
        }
      </div> */}
      <div style={{ paddingTop: "80px", paddingBottom: "80px" }}>
        {
          <Fade bottom>
            <MedSpaVisitUs />
          </Fade>
        }
      </div>
    </div>
  );
};

export default MedSpaHomePage;
