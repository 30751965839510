import React from 'react'
import "./MedSpaContactUs.css"

import ContactUsSecOne from './Sections/ContactUsSecOne'
import ContactUsSecTwo from './Sections/ContactUsSecTwo'
import ContactUsMap from './Sections/ContactUsMap'
import { Fade } from 'react-reveal'
import { Helmet } from 'react-helmet-async'

const MedSpaContactUs = () => {
  return (
    <div>
          <Helmet>
  <title>Contact Medi Spa Cosmos | Book an Appointment</title>
  <meta name="description" content="Get in touch with Medi Spa Cosmos in Bloomington, Indiana. Call, email, or visit us for expert skincare and beauty treatments." />
  <meta name="keywords" content="Med Spa Contact, Book Appointment, Med Spa Location, Skincare Consultation, Contact Medi Spa Cosmos" />
  <meta property="og:title" content="Contact Medi Spa Cosmos | Book an Appointment" />
  <meta property="og:description" content="Reach out to us for inquiries, bookings, and expert skincare advice." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://medispacosmos.com/contactus" />
</Helmet>

          <div style={{paddingTop:"25px"}}>
        <ContactUsSecTwo/>
        </div>
          <div>
          <Fade bottom>
        <ContactUsMap/>
        </Fade>
        </div>
    </div>
  )
}

export default MedSpaContactUs