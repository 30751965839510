import React from 'react'
import "./MedSpaAboutUs.css"
import MedSpaStaffDetails from '../../Components/MedSpaStaffDetails/MedSpaStaffDetails'
import ContactUsSecOne from '../MedSpaContactUs/Sections/ContactUsSecOne'
import { Helmet } from 'react-helmet-async'

const MedSpaAboutUs = () => {
  return (
    <div>
    <Helmet>
  <title>About Medi Spa Cosmos - Bloomington, Indiana</title>
  <meta name="description" content="Learn about Medi Spa Cosmos, your trusted destination for beauty, wellness, and skincare in Bloomington, Indiana." />
  <meta name="keywords" content="About Medi Spa Cosmos, Med Spa Team, Aesthetic Treatments, Skincare Experts, Med Spa Bloomington" />
  <meta property="og:title" content="About Medi Spa Cosmos - Bloomington, Indiana" />
  <meta property="og:description" content="Meet our expert team and discover our commitment to premium beauty and skincare treatments." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://medispacosmos.com/about" />
</Helmet>

      <div style={{backgroundColor:"rgb(249, 250, 251)",paddingTop:"50px",paddingBottom:"50px"}}>
        <MedSpaStaffDetails/>
      </div>
      <div style={{paddingTop:"50px",paddingBottom:"50px"}}>
        <ContactUsSecOne/>
      </div>
    </div>
  )
}

export default MedSpaAboutUs