import React from 'react'
import "./MedSpaServicesPage.css"
import ServicesSectionOne from '../../Components/ServicesSectionOne/ServicesSectionOne'
import ServiceFilters from '../../Components/ServicesCards/ServiceFilters'
import { Fade } from 'react-reveal'
import { Helmet } from 'react-helmet-async'

const MedSpaServicesPage = () => {
  return (
    <div>
    <Helmet>
  <title>Med Spa Treatments & Services | Medi Spa Cosmos</title>
  <meta name="description" content="Explore a range of Med Spa services including Botox, dermal fillers, facials, microneedling, PRP therapy, IPL laser treatments, weight loss, and more." />
  <meta name="keywords" content="Med Spa Treatments, Botox Services, Dermal Fillers, Facials, PRP Hair Treatment, IPL Skin Rejuvenation, Skin Tightening, Weight Loss, PDO Threads" />
  <meta property="og:title" content="Med Spa Treatments & Services | Medi Spa Cosmos" />
  <meta property="og:description" content="Get the best Med Spa treatments in Bloomington, Indiana. From Botox to facials and IPL therapy, we offer premium aesthetic services." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://medispacosmos.com/services" />
  <meta property="og:image" content="https://medispacosmos.com/cosmosLogo.svg" />
</Helmet>

     {
          <Fade bottom>

    <div style={{paddingTop:"50px",backgroundColor:"rgb(249 250 251)"}}>
      <ServiceFilters/>
    </div>
          </Fade>}
    </div>
  )
}

export default MedSpaServicesPage